import {createStaticPromoTile, createStaticPromoTiles} from "./staticpromo_create_tiles";
import {createImageBannerTile} from "./imagebaner_create_tile";
import TrackingService from "./tracking_service";

export default class PromoStaticPromo {

    trackClick(clickableItem, sourceUrl, filledSlots) {
        const container = this._findParentContainer(clickableItem);
        const promoType = this._getPromoType(container);
        const featureOrder = this._getFeatureOrder(container);
        const featureIndex = this.getFeatureIndex(container);
        const isPopupLink = this._isPopupLink(clickableItem);
        const masFeatureTrackingLabels = this._getMasFeatureTrackingLabels(container);

        let trackingService = new TrackingService();

        let tile = this._createTile(promoType, clickableItem, sourceUrl);

        if (isPopupLink) {
            trackingService.sendClickTrackingEventForPopup(promoType, featureOrder, featureIndex, filledSlots, tile.position, tile, masFeatureTrackingLabels);
        } else {
            trackingService.sendClickTrackingEvent(promoType, featureOrder, featureIndex, filledSlots, tile.position, tile, masFeatureTrackingLabels);
        }
    }

    setupTracking(container) {
        const promoType = this._getPromoType(container);
        const featureOrder = this._getFeatureOrder(container);
        const featureIndex = this.getFeatureIndex(container);
        const masFeatureTrackingLabels = this._getMasFeatureTrackingLabels(container);
        const sourceUrls = this._getSourceUrls(container, promoType);
        let tiles = this._createTiles(promoType, container, sourceUrls);
        let trackingService = new TrackingService();

        trackingService.sendLoadedTrackingEvent(promoType, featureOrder, featureIndex, tiles.length, tiles, masFeatureTrackingLabels);
        this._registerScrollTracking(promoType, featureOrder, tiles, trackingService);

    }

    _registerScrollTracking(promoType, featureOrder, tiles, trackingService) {
        /*                                     */
        let observer = new IntersectionObserver((entries) => {
            let intersectingEntries = entries.filter(entry => entry.isIntersecting === true);
            if (intersectingEntries.length > 0) {
                let tilePositionsToTrack = intersectingEntries.map(entry => entry.target.getAttribute("data-tile-position"));

                trackingService.sendScrollTrackingEvent(promoType, featureOrder, tilePositionsToTrack);

                intersectingEntries.forEach(entry => {
                    observer.unobserve(entry.target);
                });
            }
        });
        tiles.forEach(tile => {
            if (tile.tileContainer) {
                observer.observe(tile.tileContainer);
            }
        });
    }

    _isPopupLink(teaserItem) {
        return teaserItem.querySelector(".js_contentElementClickTracking.js_openInPaliSheet") !== null;
    }

    _createTiles(promoType, container, sourceUrls) {
        let tiles
        switch (promoType) {
            case "StaticPromotion":
                tiles = createStaticPromoTiles(container, sourceUrls)
                break;
            case "ImageBanner":
                tiles = [createImageBannerTile(container)]
                break;
        }
        return tiles;
    }

    _createTile(promoType, tileContainer, sourceUrl) {
        let tile
        switch (promoType) {
            case "StaticPromotion":
                tile = createStaticPromoTile(tileContainer, sourceUrl)
                break;
            case "ImageBanner":
                tile = createImageBannerTile(tileContainer)
                break;
        }
        return tile;
    }

    _getPromoType(container) {
        return container.getAttribute("data-promo-type");
    }

    _getFeatureOrder(container) {
        return parseInt(container.parentElement.getAttribute('data-feature-order'), 0);
    }

    _getMasFeatureTrackingLabels(container) {
        return JSON.parse(container.parentElement.getAttribute("data-feature-tracking-labels"));
    }

    getFeatureIndex(container) {
        return parseInt(container.getAttribute('data-feature-index'), 0);
    }

    _getSourceUrls(container, promoType) {
        if (promoType === "StaticPromotion") {
            const urlsAsJson = container.getAttribute("data-urls-as-json");
            return (urlsAsJson !== null) ? eval(urlsAsJson) : ["n/a"];
        } else {
            const url = container.getAttribute("data-url");
            return (url !== null) ? [url] : ["n/a"];
        }
    }

    _findParentContainer(elem) {
        const className = "promo_staticpromo-container";
        while (!elem.classList.contains(className) && (elem = elem.parentElement)) ;
        return elem;
    }

}
