export function createImageBannerTile(container) {
    return {
        "position": getTilePosition(container),
        "sourceUrl": getSourceUrl(container),
        "destinationUrl": "n/a",
        "imageId": getImageId(container),
        "tileContainer": container
    }

    function getTilePosition(item)  {
        return parseInt(item.getAttribute("data-tile-position"));
    }

    function getSourceUrl(item) {
        const url = item.getAttribute("data-url");
        return (url !== null) ? url : "n/a";
    }

    function getImageId(item) {
        const image = item.querySelector(".sp_teaserImage")
        if (image == null || !('background-image' in image.style)) {
            return "n/a"
        }
        /*                                                                                                                                          */
        const src = image.style['background-image'].replace("url(", "").replace(")", "");
        return src.substring(src.lastIndexOf("/") + 1, src.indexOf("?"));
    }
}


