export function createStaticPromoTiles(container, sourceUrls) {
    const items = container.querySelectorAll(".promo_static-promotion-teaser-item");
    return [].map.call(items, (item, i) => {
        return createStaticPromoTile(item, sourceUrls[i], i)
    });
}

export function createStaticPromoTile(tileContainer, sourceUrl) {
    if (tileContainer.querySelector(".sp_js_contentElement") === null) {
        return {}
    } else {
        return {
            "position": getTilePosition(tileContainer),
            "sourceUrl": sourceUrl,
            "destinationUrl": getDestinationUrl(tileContainer),
            "imageId": getImageId(tileContainer),
            "tileContainer": tileContainer
        }
    }
}

function getTilePosition(item)  {
    return parseInt(item.getAttribute("data-tile-position"));
}

function getDestinationUrl(item) {
    const trackingDiv = item.querySelector('.js_uni50teasertracking'); /*                */
    if (trackingDiv === null) {
        return item.querySelector('.js_contentElementClickTracking').href /*                */
    } else {
        return window.atob(trackingDiv.getAttribute('data-promo-content'))
    }
}

function getImageId(item) {
    return getImageIdFromStyle(item) || getImageIdFromImgSrc(item) || "n/a";
}

function getImageIdFromStyle(item) {
    const image = item.querySelector(".promo-card-image-4-3-s")
    if (image == null || !('background-image' in image.style)) {
        return undefined
    }
    /*                                                                                                                                          */
    const src = image.style['background-image'].replace("url(", "").replace(")", "");
    return src.substring(src.lastIndexOf("/") + 1, src.indexOf("?"));
}

function getImageIdFromImgSrc(item) {
    const image = item.querySelector(".sp_js_contentElement img")
    if (image !== null) {
        const src = image.src ? image.src : image.srcset

        /*                                                                                                                                   */
        return src.substring(src.lastIndexOf("/") + 1, src.indexOf("?"));
    } else {
        return undefined
    }
}
